import * as React from "react"
import { Helmet } from "react-helmet"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/layouts"
import Seo from "../components/seo"

import { LANG_IT } from "../utils/localization"
import { SiteInformation } from "../utils/site"

const PrivacyPage: React.FC<PageProps<SiteInformation>> = ({ data, path }) => (
  <Layout lang={LANG_IT}>
    <Helmet>
      <link
        rel="alternate"
        hrefLang={"x-default"}
        href={`${data.site.siteMetadata.siteUrl}/privacy/`}
      />
      <link
        rel="alternate"
        hrefLang={LANG_IT}
        href={`${data.site.siteMetadata.siteUrl}/it/privacy/`}
      />
    </Helmet>
    <Seo
      title="Informativa sulla privacy"
      lang={LANG_IT}
      description="Questa pagina viene utilizzata per informare i visitatori in merito alla informativa sulla privacy qualora qualcuno decidesse di utilizzare il nostro Servizio."
      path={path}
    />
    <section id="main">
      <div className="container">
        <h1>Informativa sulla privacy</h1>
        <p>
          Caplaz ha creato l'app Doodle+ come app gratuita. Questo SERVIZIO è
          fornito da Caplaz gratuitamente ed è destinato all'uso così com'è.
        </p>
        <p>
          Questa pagina viene utilizzata per informare i visitatori in merito
          alla informativa sulla privacy, l'uso e la divulgazione di
          informazioni personali qualora qualcuno decidesse di utilizzare il
          nostro Servizio.
        </p>
        <p>
          Se scegli di utilizzare il nostro Servizio, accetti la raccolta e
          l'utilizzo delle informazioni in relazione a questa politica. Le
          informazioni personali che raccogliamo vengono utilizzate per fornire
          e migliorare il servizio. Non utilizzeremo né condivideremo le tue
          informazioni con nessuno se non come descritto nella presente
          informativa sulla privacy.
        </p>
        <p>
          I termini utilizzati nella presente informativa sulla privacy hanno lo
          stesso significato dei nostri termini e condizioni, accessibili su
          Doodle+ se non diversamente definito nella presente informativa sulla
          privacy.
        </p>
        <p>
          <strong>Raccolta e utilizzo delle informazioni</strong>
        </p>
        <p>
          Per una migliore esperienza, durante l'utilizzo del nostro Servizio,
          potremmo richiederti di fornirci alcune informazioni di
          identificazione personale. Le informazioni che richiediamo verranno da
          noi conservate e utilizzate come descritto nella presente informativa
          sulla privacy.
        </p>
        <div>
          <p>
            L'app utilizza servizi di terze parti che potrebbero raccogliere
            informazioni utilizzate per identificarti.
          </p>
          <p>
            Link all'informativa sulla privacy dei fornitori di servizi terzi
            utilizzati dall'app
          </p>
          <ul>
            <li>
              <a
                href="https://www.google.com/policies/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Play Services
              </a>
            </li>
            <li>
              <a
                href="https://firebase.google.com/policies/analytics"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Analytics for Firebase
              </a>
            </li>
            <li>
              <a
                href="https://firebase.google.com/support/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Firebase Crashlytics
              </a>
            </li>
          </ul>
        </div>
        <p>
          <strong>Log Data</strong>
        </p>
        <p>
          Desideriamo informarti che ogni volta che utilizzi il nostro Servizio,
          in caso di errore nell'app raccogliamo dati e informazioni (tramite
          prodotti di terze parti) sul tuo telefono chiamati Log Data. Questi
          dati di registro possono includere informazioni come l'indirizzo del
          protocollo Internet ("IP") del dispositivo, il nome del dispositivo,
          la versione del sistema operativo, la configurazione dell'app durante
          l'utilizzo del nostro Servizio, l'ora e la data di utilizzo del
          Servizio e altre statistiche .
        </p>
        <p>
          <strong>Cookies</strong>
        </p>
        <p>
          I cookie sono file con una piccola quantità di dati che vengono
          comunemente utilizzati come identificatori univoci anonimi. Questi
          vengono inviati al tuo browser dai siti Web che visiti e vengono
          archiviati nella memoria interna del tuo dispositivo.
        </p>
        <p>
          Questo Servizio non utilizza questi "cookie" in modo esplicito.
          Tuttavia, l'app potrebbe utilizzare codice e librerie di terze parti
          che utilizzano "cookie" per raccogliere informazioni e migliorare i
          propri servizi. Hai la possibilità di accettare o rifiutare questi
          cookie e sapere quando un cookie viene inviato al tuo dispositivo. Se
          scegli di rifiutare i nostri cookie, potresti non essere in grado di
          utilizzare alcune parti di questo Servizio.
        </p>
        <p>
          <strong>Fornitori di servizi</strong>
        </p>
        <p>
          Potremmo impiegare società e individui di terze parti per i seguenti
          motivi:
        </p>
        <ul>
          <li>Per facilitare il nostro Servizio;</li>
          <li>Per fornire il Servizio per nostro conto;</li>
          <li>Per eseguire servizi relativi al Servizio; o</li>
          <li>
            Per aiutarci ad analizzare come viene utilizzato il nostro Servizio.
          </li>
        </ul>
        <p>
          Desideriamo informare gli utenti di questo Servizio che queste terze
          parti hanno accesso alle loro Informazioni Personali. Il motivo è
          svolgere i compiti loro assegnati per nostro conto. Tuttavia, sono
          obbligati a non divulgare o utilizzare le informazioni per nessun
          altro scopo
        </p>
        <p>
          <strong>Sicurezza</strong>
        </p>
        <p>
          Apprezziamo la tua fiducia nel fornirci le tue informazioni personali,
          quindi ci sforziamo di utilizzare mezzi commercialmente accettabili
          per proteggerle. Ma ricorda che nessun metodo di trasmissione su
          Internet o metodo di archiviazione elettronica è sicuro e affidabile
          al 100% e non possiamo garantirne la sicurezza assoluta.
        </p>
        <p>
          <strong>Collegamenti ad altri siti</strong>
        </p>
        <p>
          Questo Servizio può contenere collegamenti ad altri siti. Se fai clic
          su un collegamento di terze parti, verrai indirizzato a quel sito.
          Tieni presente che questi siti esterni non sono gestiti da noi.
          Pertanto, ti consigliamo vivamente di rivedere l'informativa sulla
          privacy di questi siti Web. Non abbiamo alcun controllo e non ci
          assumiamo alcuna responsabilità per il contenuto, le politiche sulla
          privacy o le pratiche di siti o servizi di terze parti.
        </p>
        <p>
          <strong>Privacy dei bambini</strong>
        </p>
        <div>
          <p>
            Questi Servizi non si rivolgono a persone di età inferiore ai 13
            anni. Non raccogliamo consapevolmente informazioni di
            identificazione personale da bambini di età inferiore ai 13 anni.
            Nel caso in cui scoprissimo che un bambino di età inferiore ai 13
            anni ci ha fornito informazioni personali, le cancelliamo
            immediatamente dai nostri server. Se sei un genitore o tutore e sei
            consapevole che tuo figlio ci ha fornito informazioni personali, ti
            preghiamo di contattarci in modo che saremo in grado di
            intraprendere le azioni necessarie.
          </p>
        </div>
        <p>
          <strong>Modifiche alla presente informativa sulla privacy</strong>
        </p>
        <p>
          Potremmo aggiornare la nostra informativa sulla privacy di volta in
          volta. Pertanto, si consiglia di rivedere periodicamente questa pagina
          per eventuali modifiche. Ti avviseremo di eventuali modifiche
          pubblicando la nuova informativa sulla privacy in questa pagina.
        </p>
        <p>Questa politica è in vigore a partire dal 13-04-2022</p>
        <p>
          <strong>Contattaci</strong>
        </p>
        <p>
          Se hai domande o suggerimenti sulla nostra Informativa sulla privacy,
          non esitare a contattarci all'indirizzo{" "}
          <a href="mailto:info@caplaz.com" className="external-links">
            info@caplaz.com
          </a>
          .
        </p>
      </div>
    </section>
  </Layout>
)

export default PrivacyPage

export const query = graphql`
  query {
    site {
      ...SiteInformation
    }
  }
`
